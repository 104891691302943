@import '../../scss/utils/color.scss';
@import '../../scss/utils/fontSize.scss';
@import '../../scss/utils/lineHeight.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

// :root {
//   --animate-duration: 200ms;
// }

body {
  scroll-behavior: auto !important;
}

.home-section-parent {
  display: flex;
  flex-direction: row;
  max-height: 100%;
  min-height: 100vh;
  // width: 100vw;
  max-width: 100vw;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.home-section {
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 100px 18px 0 18px;
  padding-top: 100px;
  position: relative;
  // height: 100%;
  // min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  &-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    // make it behind header
    transition: all 1s ease;

    z-index: -1;
    object-fit: cover;
  }

  &-hero-image {
    padding-top: 39px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &-left {
    overflow: visible;
    // to make select infront of image on right section
    z-index: 9;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    .home-title {
      font-size: $small-mobile-title;
      line-height: $small-mobile-title-line-height;
      font-weight: 800;
      color: white;
      font-family: 'Akira' !important;
      // letter-spacing: 5%;
      text-align: start;
    }
    .description-common {
      font-family: 'Montserrat' !important;
      font-weight: 500;
      font-size: $small-mobile-description;
      line-height: $small-mobile-description-line-height;
      text-decoration: none;
      text-align: start;
      color: white;
    }
    .second-section-button-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
    .third-section-button-wrapper {
      p {
        color: #fff;
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: $small-mobile-description;
        line-height: $small-mobile-description-line-height;
      }
    }
  }
  &-right {
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    align-self: center;
    // img {
    //   height: 100%;
    // }
  }
}

.pd-button {
  border: none;
  height: 40px;
  // height: ;
  text-align: center;
  color: #454545;
  &-text {
    font-weight: 700;
    font-family: 'Montserrat' !important;
    font-size: 11px;
    text-align: center;
  }

  &:hover {
    color: #fff;

    background-color: #6327af;
  }
}

.minting-sites {
  &-container {
    position: relative;
    flex: 1;
    overflow: visible;
  }
  &-wrapper {
    height: 100%;
    flex: 1;
    text-align: end !important;
    overflow: visible;
    position: absolute;
    bottom: 0;
  }
}

.scroll-indicator {
  display: flex;
  padding: 5px;
  background-color: #d9d9d9cc;
  &-icon {
    margin: 0 auto;
    width: 40px;
    height: 20px;
  }
}

@media only screen and (min-width: 425px) {
  .home-section {
    &-left {
      .home-title {
        font-size: $mobile-title;
        line-height: $mobile-title-line-height;
      }
      .description-common {
        font-size: $mobile-description;
        line-height: $mobile-description-line-height;
      }
      .third-section-button-wrapper {
        p {
          font-size: $mobile-description;
          line-height: $mobile-description-line-height;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .home-section-parent {
    display: block;
    scroll-snap-type: y mandatory !important;
    // scroll-snap-type: x mandatory;
    max-height: 100vh;
    // width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .home-section {
    flex-direction: row;
    padding-top: 0;
    height: 100%;
    min-height: 100vh;
    &-right {
      flex: 1.2 1;
      align-self: flex-end;
    }
    &-left {
      flex: 1 1;
      padding: 0 0 0 50px;
      justify-content: center;
      .home-title {
        font-size: $tablet-title;
        line-height: $tablet-title-line-height;
      }
      .description-common {
        font-weight: 500;
        font-size: $tablet-description;
        line-height: $tablet-description-line-height;
      }
      .third-section-button-wrapper {
        p {
          font-size: $tablet-description;
          line-height: $tablet-description-line-height;
        }
      }
    }
    &-hero-image {
      margin-top: 0;
    }
  }
  .pd-button {
    &-text {
      font-size: 9px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .home-section {
    &-left,
    &-right {
      flex: 1;
    }
    &-right {
      align-self: flex-end;
    }
    &-left {
      .home-title {
        font-size: $desktop-title;
        line-height: $desktop-title-line-height;
      }
      .description-common {
        font-weight: 500;
        font-size: $desktop-description;
        line-height: $desktop-description-line-height;
      }
      .third-section-button-wrapper {
        p {
          font-size: $desktop-description;
          line-height: $desktop-description-line-height;
        }
      }
    }
  }
  .pd-button {
    &-text {
      font-size: 12px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .home-section {
    &-left {
      .home-title {
        font-size: $large-desktop-title;
        line-height: $large-desktop-title-line-height;
        width: 65%;
      }
      .description-common {
        font-weight: 500;
        font-size: $large-desktop-description;
        line-height: $large-desktop-description-line-height;

        width: 50%;
      }
      .third-section-button-wrapper {
        p {
          font-size: $large-desktop-description;
          line-height: $large-desktop-description-line-height;
        }
      }
    }
    &-right {
      flex: 1;
    }
    &-hero-image {
      height: 100%;
      max-height: 750px;
    }
  }
  .pd-button {
    &-text {
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 1500px) {
  .home-section {
    &-left {
      .description-common {
        font-weight: 500;
        font-size: $large-desktop-description;
        line-height: $large-desktop-description-line-height;
      }
    }
    &-hero-image {
      height: 100%;
      max-height: 800px;
    }
  }
}

@media only screen and (min-width: 1700px) {
  .home-section {
    &-left {
      .description-common {
        font-weight: 500;
        font-size: $large-desktop-description;
        line-height: $large-desktop-description-line-height;
      }
    }
    &-hero-image {
      height: 100%;
      max-height: 850px;
    }
  }
}
